<template>
  <div
    id="main-menu-collapse-toggle"
    class="main-menu__collapse-toggle"
    :class="{ 'collapse-toggle--flipped': isCollapsed }"
    @click="toggleCollapse"
  >
    <img src="@/assets/svgs/left-arrow.svg" alt="arrow" />
  </div>
</template>

<script>
export default {
  name: 'CollapseToggle',
  props: {
    isCollapsed: Boolean,
  },
  methods: {
    toggleCollapse() {
      this.$emit('toggle-collapse')
    },
  },
}
</script>
