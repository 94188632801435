export default [
  {
    path: '/bisko/:organizationId/lookalike/models',
    name: 'lookalike-models',
    meta: {
      navActiveLink: 'lookalike-models',
      navActiveGroup: 'Lookalike'
    }
  },
  {
    path: '/bisko/:organizationId/lookalike/models/:pathMatch(.*)*',
    name: 'lookalike-models-general',
    meta: {
      navActiveLink: 'lookalike-models',
      navActiveGroup: 'Lookalike'
    }
  },
  {
    path: '/bisko/:organizationId/lookalike/segments',
    name: 'lookalike-segments',
    meta: {
      navActiveLink: 'lookalike-segments',
      navActiveGroup: 'Lookalike'
    }
  },
  {
    path: '/bisko/:organizationId/lookalike/segments/:pathMatch(.*)*',
    name: 'lookalike-segments-general',
    meta: {
      navActiveLink: 'lookalike-segments',
      navActiveGroup: 'Lookalike'
    }
  }
]
