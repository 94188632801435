export default [
  {
    path: '/bisko/:organizationId/biskql/workspace',
    name: 'workspace',
    meta: {
      navActiveLink: 'workspace',
      navActiveGroup: 'Biskql'
    }
  },
  {
    path: '/bisko/:organizationId/biskql/:tableName/preview',
    name: 'workspace-preview',
    meta: {
      navActiveLink: 'workspace',
      navActiveGroup: 'Biskql'
    }
  },
  {
    path: '/bisko/:organizationId/biskql/:tableName/schema',
    name: 'workspace-schema',
    meta: {
      navActiveLink: 'workspace',
      navActiveGroup: 'Biskql'
    }
  },
  {
    path: '/bisko/:organizationId/biskql/new-query',
    name: 'workspace-query',
    meta: {
      navActiveLink: 'workspace',
      navActiveGroup: 'Biskql'
    }
  },
  {
    path: '/bisko/:organizationId/biskql/dashboard',
    name: 'dashboard',
    meta: {
      navActiveLink: 'dashboard',
      navActiveGroup: 'Biskql'
    }
  },
  {
    path: '/bisko/:organizationId/biskql/dashboard/:pathMatch(.*)*',
    name: 'dashboard-general',
    meta: {
      navActiveLink: 'dashboard',
      navActiveGroup: 'Biskql'
    }
  }
]
