import { whitelabel } from '@/utils'
import { getUserData } from '@nodus/authentication'

const { email } = getUserData()?.profile
const biskqlAccess = email.split('@').some((val) => val === 'gjirafa.com')

export default [
  {
    id: 'organization',
    title: 'Organization',
    children: [
      {
        title: 'Properties',
        route: 'properties'
      },
      {
        title: 'Users',
        route: 'users'
      }
    ]
  },
  {
    id: whitelabel.title === 'integr8' ? 'analytics' : 'visualizer',
    title: whitelabel.title === 'integr8' ? 'Analytics' : 'Visualizer',
    route: 'analytics',
    children: [
      ...(whitelabel.title !== 'integr8'
        ? [
            {
              title: 'Dashboard',
              route: 'visualizer'
            }
          ]
        : []),
      {
        title: 'Overview',
        route: 'overview'
      },
      {
        title: 'Real Time',
        route: 'real-time'
      }
    ]
  },
  {
    id: 'data',
    title: 'Data',
    children: [
      {
        title: 'Records',
        route: 'records'
      },
      {
        title: 'Connect',
        route: 'connections'
      },
      {
        title: 'Exports',
        route: 'exports'
      }
    ]
  },
  {
    id: 'audience',
    title: 'Audience',
    children: [
      {
        title: 'Segments',
        route: 'segments'
      },
      ...(whitelabel.title !== 'integr8'
        ? [
            {
              title: 'Queries',
              route: 'queries'
            }
          ]
        : []),
      {
        title: 'Topics',
        route: 'topics'
      },
      {
        title: 'Integrations',
        route: 'integrations'
      }
    ]
  },
  {
    id: 'lookalike',
    title: 'Lookalike',
    children: [
      {
        title: 'Models',
        route: 'lookalike-models'
      },
      {
        title: 'Segments',
        route: 'lookalike-segments'
      }
    ]
  },
  ...(biskqlAccess
    ? [
        {
          id: 'biskql',
          title: 'Biskql',
          children: [
            {
              title: 'Workspace',
              route: 'workspace'
            },
            {
              title: 'Dashboard',
              route: 'dashboard'
            }
          ]
        }
      ]
    : [])
]
