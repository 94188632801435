var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navigation-item__container",
      attrs: { title: !_vm.navGroup.children ? _vm.navGroup.title : null },
    },
    [
      !_vm.navGroup.children
        ? _c(
            "div",
            { staticClass: "navigation-item" },
            [
              _c(
                "router-link",
                {
                  staticClass: "not-collapsible-header",
                  class: { "open active": _vm.isNavGroupActive },
                  attrs: {
                    id: `${_vm.navGroup.id}-menu-group`,
                    to: { name: _vm.navGroup.route },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeMenuGroups()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/svgs/products/${_vm.navGroup.id}.svg`),
                      alt: _vm.navGroup.id,
                    },
                  }),
                  _c("span", { staticClass: "navigation-item-title" }, [
                    _vm._v(_vm._s(_vm.navGroup.title)),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "navigation-item" }, [
            _c(
              "div",
              {
                staticClass: "collapsible-header",
                class: { "open active": _vm.isNavGroupActive },
                attrs: { id: `${_vm.navGroup.id}-menu-group` },
                on: { click: _vm.toggleMenuGroup },
              },
              [
                _c("img", {
                  attrs: {
                    src: require(`@/assets/svgs/products/${_vm.navGroup.id}.svg`),
                    alt: _vm.navGroup.id,
                  },
                }),
                _c("span", { staticClass: "navigation-item-title" }, [
                  _vm._v(_vm._s(_vm.navGroup.title)),
                ]),
              ]
            ),
            _c(
              "ul",
              {
                staticClass: "navigation-menu-content",
                class: { open: _vm.isNavGroupActive },
              },
              [
                _c("li", { staticClass: "navigation-item custom-item" }, [
                  _vm._v(" " + _vm._s(_vm.navGroup.title) + " "),
                ]),
                _vm._l(_vm.navGroup.children, function (navLink, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class:
                        _vm.$route?.meta?.navActiveLink === navLink.route
                          ? "router-link-exact-active"
                          : "",
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navigation-item-text navigation-item",
                          class: [
                            _vm.$route?.meta?.navActiveLink === navLink.route
                              ? "router-link-exact-active"
                              : "",
                            _vm.isBlockedForLite(
                              navLink.route,
                              _vm.organizationPlan
                            )
                              ? "navigation-item--disabled"
                              : "",
                          ],
                          attrs: {
                            to: { name: navLink.route },
                            disabled: _vm.isBlockedForLite(
                              navLink.route,
                              _vm.organizationPlan
                            ),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeMenuGroups()
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(navLink.title) + " "),
                          _vm.isBlockedForLite(
                            navLink.route,
                            _vm.organizationPlan
                          )
                            ? _c("span", { staticClass: "pro-badge" }, [
                                _vm._v("PRO"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }