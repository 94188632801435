<template>
  <div id="app" class="nodus-app__nodus-navigation">
    <navigation-menu></navigation-menu>
  </div>
</template>

<script>
export default {
  components: {
    NavigationMenu: () => import('./views/NavigationMenu.vue')
  }
}
</script>

<style>
.nodus-app__nodus-navigation {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>
