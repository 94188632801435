export default [
  {
    path: '/bisko/:organizationId/organization/properties',
    name: 'properties',
    meta: {
      navActiveLink: 'properties',
      navActiveGroup: 'Organization'
    }
  },
  {
    path: '/bisko/:organizationId/organization/properties/:pathMatch(.*)*',
    name: 'properties-general',
    meta: {
      navActiveLink: 'properties',
      navActiveGroup: 'Organization'
    }
  },
  {
    path: '/bisko/:organizationId/organization/users',
    name: 'users',
    meta: {
      navActiveLink: 'users',
      navActiveGroup: 'Organization'
    }
  },
  {
    path: '/bisko/:organizationId/organization/users/:pathMatch(.*)*',
    name: 'users-general',
    meta: {
      navActiveLink: 'users',
      navActiveGroup: 'Organization'
    }
  }
]
