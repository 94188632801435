<template>
  <div
    class="main-menu"
    :class="styleDisplay === 'expanded' ? 'main-menu--expanded' : ''"
  >
    <div id="main-menu" class="main-menu menu__container">
      <div class="main-menu__items">
        <div class="dropdown-menu__container">
          <NavGroup
            v-for="(navGroup, index) in navMenuItems[product]"
            :key="index"
            :nav-group="navGroup"
            :style-display="styleDisplay"
            :is-nav-group-active="isNavGroupActive(navGroup)"
            :organization-plan="organizationPlan"
            @collapse-groups="closeMenuGroups"
            @toggle-menu-group="toggleMenuGroup"
          />
        </div>
        <div class="collapse-toggle__container">
          <CollapseToggle
            :isCollapsed="styleDisplay === 'collapsed'"
            @toggle-collapse="toggleCollapse"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navMenuItems from '@/navigation'
import { lowerCase } from '@/utils'
import { authStore } from '@nodus/authentication'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import CollapseToggle from './CollapseToggle.vue'
import NavGroup from './NavGroup.vue'

export default {
  emits: ['layout-change'],
  setup(_, { emit }) {
    const vm = getCurrentInstance().proxy
    const styleDisplay = ref(localStorage.getItem('navMenu') || 'expanded')
    const product = computed(() => {
      return vm.$route.fullPath.split('/')[1]
    })

    const organizationPlan = computed(
      () => authStore.getters['organization/plan']
    )

    const collapseMenu = () => {
      const menu = document.getElementById('main-menu')
      menu.classList.remove('main-menu--expanded')
      menu.classList.add('main-menu--collapsed')
      styleDisplay.value = 'collapsed'
      localStorage.setItem('navMenu', 'collapsed')
      emit('layout-change', 'collapsed')
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: 'navMenu',
          newValue: 'collpased'
        })
      )
    }

    const expandMenu = () => {
      const menu = document.getElementById('main-menu')
      menu.classList.add('main-menu--expanded')
      menu.classList.remove('main-menu--collapsed')
      styleDisplay.value = 'expanded'
      localStorage.setItem('navMenu', 'expanded')
      emit('layout-change', 'expanded')
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: 'navMenu',
          newValue: 'expanded'
        })
      )
    }

    onMounted(() => {
      emit('layout-change', styleDisplay.value)
      styleDisplay.value === 'collapsed' ? collapseMenu() : expandMenu()
    })

    const isNavGroupActive = (navGroup) =>
      lowerCase(vm.$route.meta.navActiveGroup) === lowerCase(navGroup.title)

    const toggleMenuGroup = (navGroup) => {
      const header = document.getElementById(`${navGroup.id}-menu-group`)
      if (header) {
        const menu = header.nextElementSibling
        header.classList.toggle('open')
        if (menu) menu.classList.toggle('open')
      }
    }

    const closeMenuGroups = () => {
      navMenuItems[product.value].forEach((menuGroup) => {
        if (!isNavGroupActive(menuGroup)) {
          const menuGroupElem = document.getElementById(
            `${menuGroup.id}-menu-group`
          )
          if (menuGroupElem) {
            menuGroupElem.classList.remove('open')
            const nextElement = menuGroupElem.nextElementSibling
            if (nextElement) nextElement.classList.remove('open')
          }
        }
      })
    }

    const toggleCollapse = () => {
      if (styleDisplay.value === 'expanded') {
        collapseMenu()
        closeMenuGroups()
      } else expandMenu()
    }

    return {
      toggleCollapse,
      styleDisplay,
      navMenuItems,
      product,
      closeMenuGroups,
      toggleMenuGroup,
      isNavGroupActive,
      organizationPlan
    }
  },
  components: {
    NavGroup,
    CollapseToggle
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/navigation-menu.scss';
</style>
