export default [
  {
    path: '/porta/:organizationId/clients/applications',
    name: 'applications',
    meta: {
      navActiveLink: 'applications',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/applications/:pathMatch(.*)*',
    name: 'applications-general',
    meta: {
      navActiveLink: 'applications',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/api-resources',
    name: 'api-resources',
    meta: {
      navActiveLink: 'api-resources',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/api-resources/:pathMatch(.*)*',
    name: 'api-resources-general',
    meta: {
      navActiveLink: 'api-resources',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/api-scopes',
    name: 'api-scopes',
    meta: {
      navActiveLink: 'api-scopes',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/api-scopes/:pathMatch(.*)*',
    name: 'api-scopes-general',
    meta: {
      navActiveLink: 'api-scopes',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/identity-resources',
    name: 'identity-resources',
    meta: {
      navActiveLink: 'identity-resources',
      navActiveGroup: 'Clients',
    },
  },
  {
    path: '/porta/:organizationId/clients/identity-resources/:pathMatch(.*)*',
    name: 'identity-resources-general',
    meta: {
      navActiveLink: 'identity-resources',
      navActiveGroup: 'Clients',
    },
  },
]
