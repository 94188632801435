import home from './home'
import clients from './clients'
import userManagement from './user-management'
import customization from './customization'
import settings from './settings'

export default [
  ...home,
  ...clients,
  ...userManagement,
  ...customization,
  ...settings,
]
