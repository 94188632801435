export default [
  {
    path: '/bisko/:organizationId/data/records',
    name: 'records',
    meta: {
      navActiveLink: 'records',
      navActiveGroup: 'Data'
    }
  },
  {
    path: '/bisko/:organizationId/data/records/:pathMatch(.*)*',
    name: 'records-general',
    meta: {
      navActiveLink: 'records',
      navActiveGroup: 'Data'
    }
  },
  {
    path: '/bisko/:organizationId/data/connections',
    name: 'connections',
    meta: {
      navActiveLink: 'connections',
      navActiveGroup: 'Data'
    }
  },
  {
    path: '/bisko/:organizationId/data/connections/:pathMatch(.*)*',
    name: 'connections-general',
    meta: {
      navActiveLink: 'connections',
      navActiveGroup: 'Data'
    }
  },
  {
    path: '/bisko/:organizationId/data/exports',
    name: 'exports',
    meta: {
      navActiveLink: 'exports',
      navActiveGroup: 'Data'
    }
  },
  {
    path: '/bisko/:organizationId/data/exports/:pathMatch(.*)*',
    name: 'exports-general',
    meta: {
      navActiveLink: 'exports',
      navActiveGroup: 'Data'
    }
  }
]
