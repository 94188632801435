export default [
  {
    path: '/porta/:organizationId/home',
    name: 'home',
    meta: {
      navActiveLink: 'home',
      navActiveGroup: 'Home',
    },
  },
]
