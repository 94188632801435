import audience from './audience'
import biskql from './biskql'
import data from './data'
import lookalike from './lookalike'
import organization from './organization'
import visualizer from './visualizer'

export default  [
  ...organization,
  ...visualizer,
  ...data,
  ...audience,
  ...lookalike,
  ...biskql
]
