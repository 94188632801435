export default [
  {
    path: '/bisko/:organizationId/audience/segments',
    name: 'segments',
    meta: {
      navActiveLink: 'segments',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/segments/:pathMatch(.*)*',
    name: 'segments-general',
    meta: {
      navActiveLink: 'segments',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/queries',
    name: 'queries',
    meta: {
      navActiveLink: 'queries',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/queries/:pathMatch(.*)*',
    name: 'queries-general',
    meta: {
      navActiveLink: 'queries',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/query/:pathMatch(.*)*',
    name: 'query-details',
    meta: {
      navActiveLink: 'queries',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/topics',
    name: 'topics',
    meta: {
      navActiveLink: 'topics',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/topics/:pathMatch(.*)*',
    name: 'topics-general',
    meta: {
      navActiveLink: 'topics',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/integrations',
    name: 'integrations',
    meta: {
      navActiveLink: 'integrations',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/integrations/:pathMatch(.*)*',
    name: 'integrations-general',
    meta: {
      navActiveLink: 'integrations',
      navActiveGroup: 'Audience'
    }
  },
  {
    path: '/bisko/:organizationId/audience/integration/:pathMatch(.*)*',
    name: 'integration-details',
    meta: {
      navActiveLink: 'integrations',
      navActiveGroup: 'Audience'
    }
  }
]
