export default [
  {
    path: '/porta/:organizationId/user-management/users',
    name: 'management-users',
    meta: {
      navActiveLink: 'management-users',
      navActiveGroup: 'User Management',
    },
  },
  {
    path: '/porta/:organizationId/user-management/users/:pathMatch(.*)*',
    name: 'management-users-general',
    meta: {
      navActiveLink: 'management-users',
      navActiveGroup: 'User Management',
    },
  },
  {
    path: '/porta/:organizationId/user-management/roles',
    name: 'roles',
    meta: {
      navActiveLink: 'roles',
      navActiveGroup: 'User Management',
    },
  },
  {
    path: '/porta/:organizationId/user-management/roles/:pathMatch(.*)*',
    name: 'roles-general',
    meta: {
      navActiveLink: 'roles',
      navActiveGroup: 'User Management',
    },
  },
];
