export default [
  {
    id: 'home',
    title: 'Home',
    route: 'home',
  },
  {
    id: 'clients',
    title: 'Clients',
    children: [
      {
        title: 'Applications',
        route: 'applications',
      },
      {
        title: 'API Resources',
        route: 'api-resources',
      },
      {
        title: 'API Scopes',
        route: 'api-scopes',
      },
      {
        title: 'Identity Resources',
        route: 'identity-resources',
      },
    ],
  },
  {
    id: 'user-management',
    title: 'User Management',
    children: [
      {
        title: 'Users',
        route: 'management-users',
      },
      {
        title: 'Roles',
        route: 'roles',
      },
    ],
  },
  {
    id: 'customization',
    title: 'Customization',
    children: [
      {
        title: 'Universal Login',
        route: 'universal-login',
      },
      {
        title: 'Templates',
        route: 'templates',
      },
      {
        title: 'Models',
        route: 'models',
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    children: [
      {
        title: 'General Settings',
        route: 'general-settings',
      },
      {
        title: 'Languages',
        route: 'languages',
      },
      {
        title: 'Members',
        route: 'members',
      },
      {
        title: 'Authentication Flow',
        route: 'flow',
      },
      {
        title: 'Authentication Settings',
        route: 'settings',
      },
      {
        title: 'Passwordless',
        route: 'passwordless',
      },
      {
        title: 'Attack Protection',
        route: 'attack-protection',
      },
      {
        title: 'Multi-factor Auth',
        route: 'multi-factor-auth',
      },
      {
        title: 'Email Providers',
        route: 'email-providers',
      },
    ],
  },
]
