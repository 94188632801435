export default [
  {
    path: '/porta/:organizationId/customization/universal-login',
    name: 'universal-login',
    meta: {
      navActiveLink: 'universal-login',
      navActiveGroup: 'Customization'
    }
  },
  {
    path: '/porta/:organizationId/customization/universal-login/:pathMatch(.*)*',
    name: 'universal-login-general',
    meta: {
      navActiveLink: 'universal-login',
      navActiveGroup: 'Customization'
    }
  },
  {
    path: '/porta/:organizationId/customization/templates',
    name: 'templates',
    meta: {
      navActiveLink: 'templates',
      navActiveGroup: 'Customization'
    }
  },
  {
    path: '/porta/:organizationId/customization/templates/:pathMatch(.*)*',
    name: 'templates-general',
    meta: {
      navActiveLink: 'templates',
      navActiveGroup: 'Customization'
    }
  },
  {
    path: '/porta/:organizationId/customization/models',
    name: 'models',
    meta: {
      navActiveLink: 'models',
      navActiveGroup: 'Customization'
    }
  },
  {
    path: '/porta/:organizationId/customization/models/:pathMatch(.*)*',
    name: 'models-general',
    meta: {
      navActiveLink: 'models',
      navActiveGroup: 'Customization'
    }
  }
]
