export default [
  {
    path: '/porta/:organizationId/settings/general-settings',
    name: 'general-settings',
    meta: {
      navActiveLink: 'general-settings',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/settings/general-settings/:pathMatch(.*)*',
    name: 'general-settings-general',
    meta: {
      navActiveLink: 'general-settings',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/settings/languages',
    name: 'languages',
    meta: {
      navActiveLink: 'languages',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/settings/languages/:pathMatch(.*)*',
    name: 'languages-general',
    meta: {
      navActiveLink: 'languages',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/settings/members',
    name: 'members',
    meta: {
      navActiveLink: 'members',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/settings/members/:pathMatch(.*)*',
    name: 'members-general',
    meta: {
      navActiveLink: 'members',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/authentication/flow',
    name: 'flow',
    meta: {
      navActiveLink: 'flow',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/authentication/flow/:pathMatch(.*)*',
    name: 'flow-general',
    meta: {
      navActiveLink: 'flow',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/authentication/settings',
    name: 'settings',
    meta: {
      navActiveLink: 'settings',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/authentication/settings/:pathMatch(.*)*',
    name: 'settings-general',
    meta: {
      navActiveLink: 'settings',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/authentication/password-less',
    name: 'passwordless',
    meta: {
      navActiveLink: 'passwordless',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/authentication/password-less/:pathMatch(.*)*',
    name: 'passwordless-general',
    meta: {
      navActiveLink: 'passwordless',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/security/attack-protection',
    name: 'attack-protection',
    meta: {
      navActiveLink: 'attack-protection',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/security/attack-protection/:pathMatch(.*)*',
    name: 'attack-protection-general',
    meta: {
      navActiveLink: 'attack-protection',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/security/multi-factor-auth',
    name: 'multi-factor-auth',
    meta: {
      navActiveLink: 'multi-factor-auth',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/security/multi-factor-auth/:pathMatch(.*)*',
    name: 'multi-factor-auth-general',
    meta: {
      navActiveLink: 'multi-factor-auth',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/integrations/email-providers',
    name: 'email-providers',
    meta: {
      navActiveLink: 'email-providers',
      navActiveGroup: 'Settings'
    }
  },
  {
    path: '/porta/:organizationId/integrations/email-providers/:pathMatch(.*)*',
    name: 'email-providers-general',
    meta: {
      navActiveLink: 'email-providers',
      navActiveGroup: 'Settings'
    }
  }
]
