import { whitelabelConfig } from '@nodus/utilities-front'

export const whitelabel = whitelabelConfig[process.env.VUE_APP_BRAND]

export const lowerCase = (text) => {
  if (typeof text === 'string') {
    return text.toLowerCase().replace(/\s+/g, '-')
  }
  return ''
}
