var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-menu",
      class: _vm.styleDisplay === "expanded" ? "main-menu--expanded" : "",
    },
    [
      _c(
        "div",
        {
          staticClass: "main-menu menu__container",
          attrs: { id: "main-menu" },
        },
        [
          _c("div", { staticClass: "main-menu__items" }, [
            _c(
              "div",
              { staticClass: "dropdown-menu__container" },
              _vm._l(_vm.navMenuItems[_vm.product], function (navGroup, index) {
                return _c("NavGroup", {
                  key: index,
                  attrs: {
                    "nav-group": navGroup,
                    "style-display": _vm.styleDisplay,
                    "is-nav-group-active": _vm.isNavGroupActive(navGroup),
                    "organization-plan": _vm.organizationPlan,
                  },
                  on: {
                    "collapse-groups": _vm.closeMenuGroups,
                    "toggle-menu-group": _vm.toggleMenuGroup,
                  },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "collapse-toggle__container" },
              [
                _c("CollapseToggle", {
                  attrs: { isCollapsed: _vm.styleDisplay === "collapsed" },
                  on: { "toggle-collapse": _vm.toggleCollapse },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }