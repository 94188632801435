var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-menu__collapse-toggle",
      class: { "collapse-toggle--flipped": _vm.isCollapsed },
      attrs: { id: "main-menu-collapse-toggle" },
      on: { click: _vm.toggleCollapse },
    },
    [
      _c("img", {
        attrs: { src: require("@/assets/svgs/left-arrow.svg"), alt: "arrow" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }