import Vue from 'vue'
import VueRouter from 'vue-router'
import porta from './porta'
import bisko from './bisko'

Vue.use(VueRouter)

const routes = [
    ...bisko,
    ...porta,
    {
        path: '/',
        redirect: '/organizations'
      },
      {
        path: '/organizations',
        name: 'organizations'
      }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  
  export default router