<template>
  <div
    :title="!navGroup.children ? navGroup.title : null"
    class="navigation-item__container"
  >
    <div v-if="!navGroup.children" class="navigation-item">
      <router-link
        :id="`${navGroup.id}-menu-group`"
        class="not-collapsible-header"
        :class="{ 'open active': isNavGroupActive }"
        @click.native="closeMenuGroups()"
        :to="{ name: navGroup.route }"
      >
        <img
          :src="require(`@/assets/svgs/products/${navGroup.id}.svg`)"
          :alt="navGroup.id"
        />
        <span class="navigation-item-title">{{ navGroup.title }}</span>
      </router-link>
    </div>

    <div v-else class="navigation-item">
      <div
        :id="`${navGroup.id}-menu-group`"
        class="collapsible-header"
        :class="{ 'open active': isNavGroupActive }"
        @click="toggleMenuGroup"
      >
        <img
          :src="require(`@/assets/svgs/products/${navGroup.id}.svg`)"
          :alt="navGroup.id"
        />
        <span class="navigation-item-title">{{ navGroup.title }}</span>
      </div>
      <ul class="navigation-menu-content" :class="{ open: isNavGroupActive }">
        <li class="navigation-item custom-item">
          {{ navGroup.title }}
        </li>
        <li
          :class="
            $route?.meta?.navActiveLink === navLink.route
              ? 'router-link-exact-active'
              : ''
          "
          v-for="(navLink, index) in navGroup.children"
          :key="index"
        >
          <router-link
            :to="{ name: navLink.route }"
            class="navigation-item-text navigation-item"
            :disabled="isBlockedForLite(navLink.route, organizationPlan)"
            :class="[
              $route?.meta?.navActiveLink === navLink.route
                ? 'router-link-exact-active'
                : '',
              isBlockedForLite(navLink.route, organizationPlan)
                ? 'navigation-item--disabled'
                : ''
            ]"
            @click.native="closeMenuGroups()"
          >
            {{ navLink.title }}
            <span
              v-if="isBlockedForLite(navLink.route, organizationPlan)"
              class="pro-badge"
              >PRO</span
            >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isBlockedForLite } from '@nodus/utilities-front'
import { getCurrentInstance } from 'vue'

export default {
  name: 'NavGroup',
  props: {
    navGroup: Object,
    isNavGroupActive: Boolean,
    styleDisplay: String,
    organizationPlan: String
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const closeMenuGroups = () => {
      vm.$emit('collapse-groups')
    }

    const toggleMenuGroup = () => {
      if (props.navGroup.children && props.styleDisplay === 'expanded') {
        vm.$emit('toggle-menu-group', props.navGroup)
      } else {
        closeMenuGroups()
      }
    }

    return {
      isBlockedForLite,
      closeMenuGroups,
      toggleMenuGroup
    }
  }
}
</script>
