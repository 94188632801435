import { whitelabel } from '@/utils'

const activeGroup = whitelabel?.title !== 'integr8' ? 'Visualizer' : 'Analytics'

export default [
  {
    path: '/bisko/:organizationId/visualizer/dashboard',
    name: 'visualizer',
    meta: {
      navActiveLink: 'visualizer',
      navActiveGroup: 'Visualizer'
    }
  },
  {
    path: '/bisko/:organizationId/visualizer/dashboard/:pathMatch(.*)*',
    name: 'visualizer-general',
    meta: {
      navActiveLink: 'visualizer',
      navActiveGroup: 'Visualizer'
    }
  },
  {
    path: '/bisko/:organizationId/analytics/overview',
    name: 'overview',
    meta: {
      navActiveLink: 'overview',
      navActiveGroup: activeGroup
    }
  },
  {
    path: '/bisko/:organizationId/analytics/overview/:pathMatch(.*)*',
    name: 'overview-general',
    meta: {
      navActiveLink: 'overview',
      navActiveGroup: activeGroup
    }
  },
  {
    path: '/bisko/:organizationId/analytics/real-time',
    name: 'real-time',
    meta: {
      navActiveLink: 'real-time',
      navActiveGroup: activeGroup
    }
  },
  {
    path: '/bisko/:organizationId/analytics/real-time/:pathMatch(.*)*',
    name: 'real-time-general',
    meta: {
      navActiveLink: 'real-time',
      navActiveGroup: activeGroup
    }
  }
]
